import React, { useEffect, useState } from 'react'
import firebase from 'firebase'
import { ConfigProvider } from 'antd'
import { IntlProvider } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import themes from '@iso/config/theme/theme.config'
import AppLocale from '@iso/config/translation'

import profileActions from '@iso/redux/profile/actions'

const { updateUserProfile } = profileActions

export default function AppProvider({ children }) {
  const { locale } = useSelector(state => state.LanguageSwitcher.language)
  const { themeName } = useSelector(state => state.ThemeSwitcher.changeThemes)
  const currentAppLocale = AppLocale[locale]
  const dispatch = useDispatch()
  const isLoggedIn = useSelector(state => state.Auth.idToken)
  const profile = useSelector(state => state.profile.profile)

  useEffect(() => {
    if (isLoggedIn) {
      return firebase.auth().onAuthStateChanged(() => {
        dispatch(updateUserProfile())
      })
    }
  }, [isLoggedIn, dispatch])

  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <ThemeProvider theme={themes[themeName]}>
          {(!isLoggedIn || profile) && children}
        </ThemeProvider>
      </IntlProvider>
    </ConfigProvider>
  )
}
