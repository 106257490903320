import { db } from './firebase'
import firebaseConfig from '@iso/config/firebase.config'
import siteConfig from '@iso/config/site.config'
import axios from 'axios'
import copy from 'copy-to-clipboard'
import notification from '@iso/components/Notification'

const { apiKey, projectId } = firebaseConfig
const { bundleName, iosAppStoreId } = siteConfig

export async function createDynamicLink(note) {
  await axios({
    method: 'post',
    url: `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${apiKey}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      dynamicLinkInfo: {
        //check domainUriPrefix productId because on production dynamic link URL prefix is different than projectId
        domainUriPrefix: `https://${
          projectId === 'chalknotes-a5577' ? 'chalknotes' : projectId
        }.page.link`,
        link: `https://${projectId}.web.app/trail?id=${note.batchId}}`,
        iosInfo: {
          iosBundleId: bundleName,
          iosAppStoreId: iosAppStoreId,
        },
        androidInfo: {
          androidPackageName: bundleName,
        },
        socialMetaTagInfo: {
          socialTitle: `${note.title}`,
          socialDescription: 'Checkout my trail on ChalkNotes',
          socialImageLink: note.image ? note.image : '',
        },
        desktopInfo: {
          desktopFallbackLink: `https://${projectId}.web.app/dashboard/&trail?id=${note.batchId}`,
        },
      },
      suffix: {
        option: 'UNGUESSABLE',
      },
    },
  })
    .then(res => {
      copy(res.data.shortLink, {
        debug: true,
        message: 'Press to copy',
      })
      notification('success', 'Link copied!')
    })
    .catch(err => {
      console.log(err)
      notification('error', 'Something went wrong try again later!')
    })
}

export function convertCollectionsSnapshotToMap(snapshots) {
  return snapshots.docs.reduce((accumulator, doc) => {
    accumulator[doc.id] = doc.data()
    return accumulator
  }, {})
}

export async function getNewDocRef(collectionName) {
  return await db.collection(collectionName).doc()
}

export async function addDocument(collectionName, documentData) {
  return await db
    .collection(collectionName)
    .add(documentData)
    .then(docRef => docRef.id)
}

export async function setDocument(collectionName, docRef, documentData) {
  return await db.collection(collectionName).doc(docRef).set(documentData)
}

export async function getDocuments(collectionName) {
  return await db
    .collection(collectionName)
    .get()
    .then(querySnapshot =>
      querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
    )
}

export async function getDocumentsByQuery(collectionName, query) {
  console.log(...query, collectionName, 'test')

  return await db
    .collection(collectionName)
    .where(...query)
    .get()
    .then(querySnapshot =>
      querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
    )
}

export async function deleteDocuments(collectionName) {
  const collectionRef = db.collection(collectionName)
  var batch = db.batch()
  await collectionRef
    .get()
    .then(querySnapshot =>
      querySnapshot.docs.map(doc => batch.delete(collectionRef.doc(doc.id)))
    )

  return await batch.commit().then(() => {
    console.log('Batch Deletion successfully committed!')
  })
}

export const addCollectionAndDocuments = async (
  collectionKey,
  objectsToAdd
) => {
  const collectionRef = db.collection(collectionKey)
  const batch = db.batch()
  objectsToAdd.forEach(obj => {
    const newDocRef = collectionRef.doc()
    batch.set(newDocRef, obj)
  })
  return await batch.commit().then(() => {
    console.log('Batch Addition successfully committed!')
  })
}
// const { title, items } = doc.data();
//     return {
//       routeName: encodeURI(title.toLowerCase()),
//       id: doc.id,
//       title,
//       items,
//     };
//   });
//   console.log(transformedCollection);
//   return transformedCollection.reduce((accumulator, collection) => {
//     accumulator[collection.title.toLowerCase()] = collection;
//     return accumulator;
//   }, {});
