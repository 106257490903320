import styled from 'styled-components'
import { palette, font } from 'styled-theme'
import { colors } from '@iso/assets/styles/constants'

const RecorderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .styles_button__3Vugn {
    background: ${colors.grey.dark};
  }

  .player-and-delete-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
  }

  .record-buttons-wrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;

    .custom-file-input {
      color: transparent;
      width: 112px;
    }
    .custom-file-input::-webkit-file-upload-button {
      visibility: hidden;
    }
    .custom-file-input::before {
      content: 'UPLOAD';
      color: ${colors.black};
      font-family: ${font('primary', 0)};
      display: inline-block;
      background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
      border: 1px solid #999;
      border-radius: 5px;
      padding: 5px 30px;
      margin-top: 19px;
      outline: none;
      white-space: nowrap;
      -webkit-user-select: none;
      cursor: pointer;
      text-shadow: 1px 1px #fff;
      font-weight: 500;
      font-size: 10pt;
    }
    .custom-file-input:hover::before {
      border-color: black;
    }
    .custom-file-input:active {
      outline: 0;
    }
    .custom-file-input:active::before {
      background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
    }
  }
`

export default RecorderWrapper
