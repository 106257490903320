export const COMMUNITY_TYPES = ['public', 'exclusive', 'hidden']

export const COMMUNITY_EXCLUSIVE_TYPES = ['exclusive', 'hidden']

export const COMMUNITY_CATEGORIES = [
  'Food',
  'Lifestyle',
  'Fashion',
  'History',
  'Literary',
  'Art',
  'Music',
  'Dance',
  'Theatre',
  'Nature & Outdoor',
  'Wine & Beer',
  'Nightlife',
  'Wellness & Fitness',
  'Sightseeing & Travel',
  'Sports',
  'Pets & Animals',
  'Science',
  'Family',
]

export const BRAND_IDENTITY_FIELDS = ['logo', 'backgroundColor', 'textColor']

export const PERKS_USERS = ['owner', 'contributor', 'admin']

export const COMMUNITY_FIELDS = [
  'name',
  'type',
  'category',
  'questionForMembers',
  'description',
  'rules',
]

export const COMMUNITY_BRAND_FIELDS = ['logo', 'textColor', 'backgroundColor']

export const COMMUNITY_ADMINS_TYPES = ['admin', 'owner']

export const COMMUNITY_MEMBERS_TYPES = ['contributor', 'basicMember']
