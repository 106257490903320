import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import notification from '../../../components/Notification'
import Recorder from 'react-mp3-recorder'
import { Button, Tooltip } from 'antd'
import { COLLECTIONS } from '../../../constants'
import useUploadFile from '../../../library/hooks/uploadCustomHook'
import notesActions from '@iso/redux/notes/actions'
import images from '../../../assets/images/index'
import RecorderWrapper from './AudioRecorder.style'
import AudioPlayer from './AudioPlayer/AudioPlayer'

const { deleteMedia } = notesActions

const AudioRecorderWrap = ({ form, name, value, handleChange }) => {
  const dispatch = useDispatch()
  const [state, setState] = useState({
    blob: null,
    blobURL: null,
    audioUrl: null,
  })

  useEffect(() => {
    value &&
      setState(currState => ({
        ...currState,
        blobURL: value,
      }))
  }, [value])

  const [uploadFile] = useUploadFile(COLLECTIONS.AUDIO)

  const handelUpload = async blob => {
    try {
      const result = await uploadFile(blob)
      handleChange(form, name, result.fileUrl)
    } catch (err) {
      console.log(err)
      notification('error', 'Something went wrong, try to upload again.')
      return null
    }
  }

  const _onRecordingComplete = blob => {
    const blobURL = URL.createObjectURL(blob)
    setState({ blob: blob, blobURL: blobURL })
    handelUpload(blob)
  }

  const _onRecordingError = err => {
    notification('error', 'Something went wrong, try to upload again.')
    console.log('recording error', err)
  }

  const handleDelete = file => {
    dispatch(deleteMedia('audio', file || value))
    handleChange(form, name, null)
    setState({ blob: null, blobURL: null })
  }

  const handleUploadCustomFile = e => {
    const isAudio = e.target.files[0].type.indexOf('audio/') === 0
    if (!isAudio) {
      notification('error', 'You can only upload audio file!')
    }
    const file = URL.createObjectURL(e.target.files[0])
    setState({ blob: e.target.files[0], blobURL: file })
    handelUpload(e.target.files[0])
  }

  return (
    <RecorderWrapper>
      {state.blobURL ? (
        <div className="player-and-delete-wrap">
          <AudioPlayer url={state.blobURL} />
          <Button
            className="round-small-btn"
            onClick={() => handleDelete(state.blobURL)}
          >
            <img src={images.xPink} alt="Delete" />
          </Button>
        </div>
      ) : (
        <div className="record-buttons-wrap">
          <Tooltip placement="topLeft" title="Press and hold to record">
            <Recorder
              onRecordingComplete={_onRecordingComplete}
              onRecordingError={_onRecordingError}
            />
          </Tooltip>
          <input
            className="custom-file-input"
            type="file"
            onChange={handleUploadCustomFile}
          />
        </div>
      )}
    </RecorderWrapper>
  )
}

export default AudioRecorderWrap
