import * as React from 'react'

import useAudio from '../../../../library/hooks/useAudioCustomHook'
import TimeBar from './TimeBar'
import PlaybackButton from './PlaybackButton'
import AudioPlayerWrap from './AudioPlayer.style.js'
import { useEffect } from 'react'

function AudioPlayer({ url }) {
  const [audioElement, audioProps] = useAudio(url)

  return (
    <AudioPlayerWrap>
      {audioElement}

      {audioProps.isLoading ? (
        <div style={{ color: 'white' }}>Loading...</div>
      ) : (
        <div className="controls">
          <PlaybackButton
            onClick={audioProps.togglePlaybackStatus}
            playbackStatus={audioProps.playbackStatus}
          />
          <TimeBar
            currentTime={audioProps.currentTime}
            isSeeking={audioProps.isSeeking}
            duration={audioProps.duration}
            progress={audioProps.progress}
            setTime={audioProps.setTime}
          />
        </div>
      )}
    </AudioPlayerWrap>
  )
}

export default AudioPlayer
