import logoWhite from './logo-white.svg'
import logoGradient from './icons_chalknotes/chalknotes_logo_beta.svg'
import noChalknotes from './icons_chalknotes/noChalknotes.svg'
import addNoteIcon from './icons_chalknotes/add_note_gradient.svg'
import pinNote from './icons_chalknotes/map_pin_note.svg'
import xPink from './icons_chalknotes/x_pink.svg'
import peopleGradientCover from './cn_people_gradient.png'
import checkMarkPink from './icons_chalknotes/checkmark_pink.svg'
import cameraGradient from './icons_chalknotes/camera_gradient.svg'
import micGradient from './icons_chalknotes/microphone_gradient.svg'
import eyeWhite from './icons_chalknotes/eye_white.svg'
import privateNote from './icons_chalknotes/private_note.svg'
import privateTrail from './icons_chalknotes/private_trail.svg'
import publicNote from './icons_chalknotes/public_note.png'
import publicTrail from './icons_chalknotes/public_trail.svg'
import logoSquare from './icons_chalknotes/CNfavicon.png'
import greyPlaceholder from './icons_chalknotes/greyscale_placeholder.png'
import clusterCircle from './icons_chalknotes/cluster_icon.png'
import goToLocationIcon from './icons_chalknotes/jump_to_location.png'
import chalkNotesBackground from './chalkNotesBackground.png'
import editPhoto from './icons_chalknotes/edit_photo.png'
import xCircleGradient from './icons_chalknotes/x_circle_gradient.svg'
import questionMarkGrey from './icons_chalknotes/question_mark_round_grey.svg'
import moreOptionsIcon from './icons_chalknotes/dots_round_grey.svg'

import { ReactComponent as ColorPickerIcon } from './icons_chalknotes/color_picker_icon.svg'
import { ReactComponent as ArrowDownFill } from './icons_chalknotes/arrow_down_fill_gray.svg'
import { ReactComponent as MembersFilled } from './icons_chalknotes/members_gray.svg'

export default {
  logoSquare,
  logoWhite,
  logoGradient,
  noChalknotes,
  addNoteIcon,
  pinNote,
  xPink,
  checkMarkPink,
  cameraGradient,
  micGradient,
  eyeWhite,
  privateNote,
  privateTrail,
  publicNote,
  publicTrail,
  greyPlaceholder,
  clusterCircle,
  goToLocationIcon,
  chalkNotesBackground,
  editPhoto,
  ColorPickerIcon,
  ArrowDownFill,
  MembersFilled,
  xCircleGradient,
  peopleGradientCover,
  questionMarkGrey,
  moreOptionsIcon
}
