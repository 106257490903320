import actions from './actions'
import { uuid } from 'uuidv4'
import omit from 'lodash/omit'
import isEqual from 'lodash/isEqual'
import { update } from 'lodash'

const initState = {
  isLoading: false,
  errorMessage: false,
  notes: null,
  usersNotes: null,
  allPublicNotes: null,
  modalActive: false,
  trailModalActive: false,
  reoderModalActive: false,
  addSpotToExistingTrail: false,
  trailData: {},
  draftSpot: {},
  spots: []
}

let newSpots

export default function reducer(
  state = initState,
  { type, payload, newRecord }
) {
  switch (type) {
    case actions.CLEAR_STATE:
      return {
        ...state,
        isLoading: false,
        trailData: initState.trailData,
        draftSpot: initState.draftSpot,
        addSpotToExistingTrail: false
      }
    case actions.CLEAR_SPOTS_STATE:
      return {
        ...state,
        spots: initState.spots
      }
    case actions.EDIT_SPOTS:
      return {
        ...state,
        spots: payload.data
      }
    case actions.LOAD_FROM_FIRESTORE:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false
      }
    case actions.LOAD_FROM_FIRESTORE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        notes: payload.sentNotes,
        usersNotes: payload.usersNotes,
        allPublicNotes: payload.allPublicNotes,
        addSpotToExistingTrail: false,
        errorMessage: false
      }
    case actions.LOAD_FROM_FIRESTORE_ERROR:
      return {
        ...state,
        isLoading: false,
        addSpotToExistingTrail: false,
        errorMessage: 'There is a loading problem'
      }
    case actions.FIRESTORE_UPDATE_SPOT_REQUEST:
      return {
        ...state,
        errorMessage: false,
        modalActive: false
      }
    case actions.FIRESTORE_UPDATE_SPOT_SUCCESS:
      return {
        ...state,
        isLoading: false
      }
    case actions.FIRESTORE_UPDATE_SPOT_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'There is a loading problem'
      }

    case actions.FIRESTORE_UPDATE_TRAIL_REQUEST:
      return {
        ...state,
        errorMessage: false,
        trailModalActive: false
      }
    case actions.FIRESTORE_UPDATE_TRAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        addSpotToExistingTrail: false
      }
    case actions.FIRESTORE_UPDATE_TRAIL_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'There is a loading problem'
      }
    case actions.TOGGLE_FIRESTORE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        draftSpot: payload.data ? payload.data : initState.draftSpot
      }

    case actions.TOGGLE_TRAIL_MODAL:
      return {
        ...state,
        trailModalActive: !state.trailModalActive,
        trailData: payload.data ? payload.data : initState.trailData
      }

    case actions.TOGGLE_REORDER_MODAL:
      return {
        ...state,
        reoderModalActive: !state.reoderModalActive
      }

    case actions.HANDLE_CHANGE:
      let newData = Object.assign(
        {},
        payload.form === 'spot' ? state.draftSpot : state.trailData
      )
      newData[payload.name] = payload.value
      if (payload.form === 'spot') {
        return {
          ...state,
          draftSpot: newData
        }
      } else {
        return {
          ...state,
          trailData: newData
        }
      }

    case actions.HANDLE_PRICE:
      newSpots = [...state.spots]
      newSpots[0] = {
        ...newSpots[0],
        price: payload.value
      }
      return {
        ...state,
        spots: newSpots
      }

    case actions.HANDLE_CURRENCY:
      newSpots = [...state.spots]
      if (payload.value != null) {
        newSpots[0] = {
          ...newSpots[0],
          currency: payload.value.toLowerCase(),
          isPremium: true
        }
      } else {
        newSpots[0] = {
          ...newSpots[0],
          currency: null,
          isPremium: null
        }
      }
      return {
        ...state,
        spots: newSpots
      }

    case actions.ADD_DRAFTSPOT:
      newSpots = [...state.spots]
      if (payload.draftSpot.key !== undefined) {
        const spotIndex = newSpots.findIndex(
          spot => spot.id === payload.draftSpot.id
        )

        newSpots[spotIndex] = payload.draftSpot
      } else {
        const id = uuid()
        newSpots.push({
          ...payload.draftSpot,
          id: id
        })
      }
      return {
        ...state,
        spots: newSpots,
        draftSpot: null,
        modalActive: false
      }

    case actions.ADD_TRAIL:
      return {
        ...state,
        trailData: {
          ...state.trailData,
          ...payload.trailData,
          type: state.spots.length !== 1 ? 'trail' : 'note'
        },
        trailModalActive: false
      }

    case actions.ADD_TRAIL:
      newSpots = [...state.spots]
      let indexOfSplitSpot = newSpots.map(spot => spot.id).indexOf(payload.id)
      newSpots[indexOfSplitSpot] = {
        ...newSpots[indexOfSplitSpot],
        splitQuestion: payload.splitQuestion
      }
      return {
        ...state,
        spots: newSpots
      }

    case actions.EDIT_SPOT_LOCATION:
      newSpots = [...state.spots]
      let indexOfSpot = newSpots.map(spot => spot.id).indexOf(payload.id)
      newSpots[indexOfSpot] = {
        ...newSpots[indexOfSpot],
        location: payload.location
      }
      return {
        ...state,
        spots: newSpots
      }

    case actions.EDIT_SPOT_PREVSPOTS:
      newSpots = [...state.spots]
      let indexOfSpotToChange = newSpots
        .map(spot => spot.id)
        .indexOf(payload.id)
      let newPrevSpots = []
      if (newSpots[indexOfSpotToChange]?.prevSpots) {
        newPrevSpots = newSpots[indexOfSpotToChange].prevSpots
      }
      newPrevSpots.push(payload.prevSpotId)
      if (newSpots[indexOfSpotToChange]?.splitAnswer != null) {
        newSpots[indexOfSpotToChange] = {
          ...newSpots[indexOfSpotToChange],
          prevSpots: newPrevSpots
        }
      } else {
        newSpots[indexOfSpotToChange] = {
          ...newSpots[indexOfSpotToChange],
          prevSpots: newPrevSpots,
          splitAnswer: payload.splitAnswer
        }
      }
      return {
        ...state,
        spots: newSpots
      }

    case actions.ADD_SPOT_TO_EXISTING_TRAIL:
      return {
        ...state,
        spots: payload.spots,
        trailData: payload.trail,
        addSpotToExistingTrail: true
      }

    case actions.DELETE_DRAFTSPOT:
      newSpots = [...state.spots]
      let deletedSpot = { ...omit(payload.spot, ['key']) }
      return {
        ...state,
        spots: newSpots.filter(spot => !isEqual(spot, deletedSpot))
      }

    default:
      return state
  }
}
