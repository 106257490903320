import profileActions from './actions'

const initState = {
  profile: null,
  users: null,
  loading: false,
  loadingUsers: false,
  error: null,
}

export default function profileReducer(state = initState, action) {
  switch (action.type) {
    case profileActions.UPDATE_USER_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case profileActions.UPDATE_USER_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
      }
    case profileActions.UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.profile,
        loading: false,
      }
    case profileActions.GET_PREMIUM_USERS_PROFILES_REQUEST:
      return {
        ...state,
        loadingUsers: true,
      }
    case profileActions.GET_PREMIUM_USERS_PROFILES_ERROR:
      return {
        ...state,
        loadingUsers: false,
      }
    case profileActions.GET_PREMIUM_USERS_PROFILES_SUCCESS:
      return {
        ...state,
        users: action.users,
        loadingUsers: false,
      }
    default:
      return state
  }
}
