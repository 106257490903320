import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import actions from './actions'
import firebase from 'firebase'
import { omit, isEqual } from 'lodash'
import { uuid } from 'uuidv4'
import { rsf, db, storage } from '@iso/lib/firebase/firebase'
import notification from '../../components/Notification'
import { getFileNameFromURL } from '../../library/helpers/utility'
import {
  convertCollectionsSnapshotToMap,
  deleteDocuments,
  addCollectionAndDocuments,
} from '@iso/lib/firebase/firebase.util'
import moment from 'moment'
import { COLLECTIONS } from '../../constants'
import { createBrowserHistory } from 'history'

const history = createBrowserHistory()

const notesRef = db.collection(COLLECTIONS.NOTES)
const usersRef = db.collection(COLLECTIONS.USERS)

function* loadFromFirestore() {
  const {
    profile: { profile, users },
    Communities: { communities },
  } = yield select()

  try {
    const user = profile

    const sortDates = (a, b) => {
      return a.startDate.toMillis() - b.startDate.toMillis()
    }

    const constructNotes = notes => {
      return notes.docs.map(noteDoc => {

        return {
          ...noteDoc.data(),
          id: noteDoc.id,
          // communityDetails:
          //   communities &&
          //   communities.find(
          //     community =>
          //       community.id === noteDoc.data().communityDetails.communityId
          //   ),
          createdBy:
            users && users.find(user => user.id === noteDoc.data().createdBy.id),
        }
      }, [])
    }

    const usersNotes = yield call(() => {
      return notesRef.where('createdBy', '==', usersRef.doc(user.id)).get()
    })

    const publicNotes = yield call(() => {
      return notesRef.where('createdBy', '==', usersRef.doc(user.id)).get()
    })

    // const publicNotes = yield call(() => {
    //   // all original public notes (without the batch copies)
    //   return notesRef.where('createdFor', '==', 'all').get()
    // })

    const sentNotes = yield call(() => constructNotes(usersNotes))

    // const allPublicNotes = yield call(() => constructNotes(publicNotes))

    // const finalAllPublicNotes = allPublicNotes
    //   .map(note => ({
    //     ...note
    //   }))
    //   .sort(sortDates)
    //   .reverse()

    const finalSentNotes = sentNotes
      .map(note => ({
        ...note,
      }))
      .sort(sortDates)
      .reverse()

    const finalUsersPublicNotes = finalSentNotes
    // &&
    // finalSentNotes.filter(
    //   note => note.createdFor.length > 1 && note.createdFor.includes('all')
    // )

    yield put(
      actions.loadFromFireStoreSuccess(
        finalSentNotes,
        finalUsersPublicNotes
        // finalAllPublicNotes
      )
    )
  } catch (error) {
    console.log(error)
    notification('error', 'Something went wrong!')
    yield put(actions.loadFromFireStoreError(error))
  }
}

function* storeIntoFirestore({ payload: { data } }) {
  const {
    profile: { profile },
  } = yield select()

  console.log(data.communityDetails)

  try {
    const user = profile

    const id = uuid()
    const communityId = data?.communityDetails?.communityId
    const startDate = data?.startDate ? data.startDate.valueOf() : Date.now()
    const expiryDate = data?.expiryDate
      ? data.expiryDate.valueOf()
      : Date.now() + 4 * 24 * 60 * 60 * 1000
    const isTrail = data.spots.length !== 1

    yield call(rsf.firestore.addDocument, COLLECTIONS.NOTES, {
      communityDetails: data?.communityDetails,
      createdBy: usersRef.doc(user.id),
      title: isTrail ? data?.title : null,
      subtitle: isTrail && data?.subtitle ? data?.subtitle : null,
      text: isTrail && data?.text ? data.text : null,
      image: isTrail && data?.image ? data.image : null,
      trinketAward: isTrail && data?.trailTrinket ? data.trailTrinket : null,
      audio: isTrail && data?.audio ? data.audio : null,
      backgroundSound:
        isTrail && data?.backgroundSound ? data.backgroundSound : null,
      proximityLock: data?.proximityLock ? data?.proximityLock : true,
      createdAt: firebase.firestore.Timestamp.fromMillis(Date.now()),
      startDate: firebase.firestore.Timestamp.fromMillis(startDate),
      expiryDate: firebase.firestore.Timestamp.fromMillis(expiryDate),
      trailTags: isTrail && data.trailTags,
      isLinear: data?.isLinear || false,
      isSplit: (isTrail && data?.isSplit) || false,
      commentsOn: data?.commentsOn || false,
      type: isTrail ? 'trail' : 'note',
      spots: data.spots.map(
        ({
          id,
          title = null,
          audio = null,
          image = null,
          text = null,
          spotType = 'standard',
          location,
          locationDetails,
          prevSpots = null,
          nextSpots = null,
          price = null,
          currency = null,
          isPremium = false,
          splitQuestion = null,
          splitAnswer = null
        }) => ({
          id,
          communityId,
          title,
          text,
          audio,
          image,
          spotType,
          locationDetails,
          location: new firebase.firestore.GeoPoint(location.lat, location.lng),
          prevSpots,
          nextSpots,
          price,
          currency,
          isPremium,
          splitQuestion,
          splitAnswer
        })
      ),
    })

    notification(
      'success',
      `${data.spots.length !== 1 ? 'Trail' : 'Note'}  created!`
    )

    yield put({ type: actions.CLEAR_STATE })
  } catch (error) {
    console.log(error)
    notification('error', 'Something went wrong!')
    yield put(actions.saveIntoFireStoreError(error))
  }
}

function* updateSpotInFirestore({ payload }) {
  const noteId = payload.editedSpot.noteId
  const spotId = payload.editedSpot.id

  try {
    const noteToBeUpdated = yield call(() => {
      return notesRef.doc(noteId).get()
    })

    const originalNote = yield call(() => {
      return noteToBeUpdated.data()
    })

    const newSpots = [...originalNote.spots]

    const spotIndex = newSpots.findIndex(spot => spot.id === spotId)

    newSpots[spotIndex] = {
      ...omit(payload.editedSpot, [
        'createdAt',
        'startDate',
        'expiryDate',
        'createdBy',
        'communityDetails',
        'proximityLock',
        'commentsOn',
        'trailData',
      ]),
      location:
        !isNaN(payload.editedSpot.location.lat) &&
        !isNaN(payload.editedSpot.location.lng)
          ? new firebase.firestore.GeoPoint(
              payload.editedSpot.location.lat,
              payload.editedSpot.location.lng
            )
          : payload.editedSpot.location,
    }

    const newTrailData = {
      ...omit(payload.editedSpot.trailData, [
        'id',
        'createdAt',
        'startDate',
        'expiryDate',
      ]),
    }

    yield call(() => {
      if (
        moment.isMoment(payload.editedSpot.trailData.startDate) ||
        moment.isMoment(payload.editedSpot.trailData.expiryDate)
      ) {
        const startDate = payload.editedSpot.trailData.startDate.valueOf()
        const expiryDate = payload.editedSpot.trailData.expiryDate.valueOf()
        return notesRef.doc(noteId).update({
          startDate: firebase.firestore.Timestamp.fromMillis(startDate),
          expiryDate: firebase.firestore.Timestamp.fromMillis(expiryDate),
          ...newTrailData,
          spots: newSpots,
        })
      } else {
        return notesRef.doc(noteId).update({
          ...newTrailData,
          spots: newSpots,
        })
      }
    })

    yield put({
      type: actions.LOAD_FROM_FIRESTORE,
    })
  } catch (error) {
    console.log(error)
    notification('error', 'Something went wrong!')
    yield put({ type: actions.FIRESTORE_UPDATE_SPOT_ERROR })
  }
}

function* updateTrailInFirestore({ payload }) {
  const noteId = payload.editedTrail?.id

  const {
    Notes: { trailData, addSpotToExistingTrail },
  } = yield select()

  try {
    const noteToBeUpdated = yield call(() => {
      return notesRef.doc(noteId).get()
    })

    const originalNote = yield call(() => {
      return noteToBeUpdated.data()
    })

    const updatedTrail = omit(payload.editedTrail, [
      'id',
      'createdBy',
      'createdAt',
      'startDate',
      'expiryDate',
      'key',
    ])

    const originalSpots = [...originalNote.spots]

    let newSpots = updatedTrail.spots

    if (!isEqual(originalSpots, newSpots)) {
      newSpots = newSpots.map(spot => {
        if (!isNaN(spot.location.lat) && !isNaN(spot.location.lng)) {
          spot = {
            ...spot,
            location: new firebase.firestore.GeoPoint(
              spot.location.lat,
              spot.location.lng
            ),
          }
        }
        return spot
      })
    }

    yield call(() => {
      if (
        moment.isMoment(trailData?.startDate) ||
        moment.isMoment(trailData?.expiryDate)
      ) {
        const startDate = trailData.startDate.valueOf()
        const expiryDate = trailData.expiryDate.valueOf()
        return notesRef.doc(noteId).update({
          startDate: firebase.firestore.Timestamp.fromMillis(startDate),
          expiryDate: firebase.firestore.Timestamp.fromMillis(expiryDate),
          spots: newSpots,
          ...updatedTrail,
          communityDetails: {
            communityId: updatedTrail.communityDetails?.id
              ? updatedTrail.communityDetails?.id
              : updatedTrail.communityDetails?.communityId,
            name: updatedTrail.communityDetails.name,
          },
        })
      } else {
        return notesRef.doc(noteId).update({
          ...updatedTrail,
          spots: newSpots,
          communityDetails: {
            communityId: updatedTrail.communityDetails?.id
              ? updatedTrail.communityDetails?.id
              : updatedTrail.communityDetails?.communityId,
            name: updatedTrail.communityDetails.name,
          },
        })
      }
    })

    yield put({
      type: actions.LOAD_FROM_FIRESTORE,
    })
    if (addSpotToExistingTrail) {
      history.push('/dashboard/trails')
    }
  } catch (error) {
    console.log(error)
    notification('error', 'Something went wrong!')
    yield put({ type: actions.FIRESTORE_UPDATE_SPOT_ERROR })
  }
}

function* deleteMedia({ payload: { type, file } }) {
  const storageRef = storage.ref()
  const filePath = getFileNameFromURL(file)
  const mediaRef = storageRef.child(filePath)

  try {
    mediaRef.delete().then(() => notification('success', 'File deleted!'))
  } catch (err) {
    console.log(err)
    notification('error', 'File not found!')
  }
}

function* deleteSpotFirestore({ payload }) {
  const { spot } = payload

  try {
    const notes = yield call(() => notesRef.doc(spot.noteId).get())

    const originalNote = yield call(() => notes.data())

    const deletedId = spot.id

    yield call(() => {
      if (originalNote.spots.length === 1) {
        notesRef.doc(spot.noteId).delete()
      } else {
        notesRef.doc(spot.noteId).update({
          type: originalNote.spots.length === 2 ? 'note' : originalNote.type,
          spots: originalNote.spots.filter(spot => spot.id !== deletedId),
        })
      }
    })

    yield put({
      type: actions.LOAD_FROM_FIRESTORE,
    })
  } catch (err) {
    console.log(err)
    notification('error', 'Something went wrong!')
  }
}

function* deleteTrailFirestore({ payload: { trail } }) {
  const notesRef = db.collection(COLLECTIONS.NOTES)

  try {
    yield call(() => notesRef.doc(trail.id).delete())
    yield put({
      type: actions.LOAD_FROM_FIRESTORE,
    })
  } catch (err) {
    console.log(err)
    notification(
      'error',
      "You cannot delete a trail 5 minutes after creation. Change time and date to 'scheduled' to delete!"
    )
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_FIRESTORE, loadFromFirestore),
    takeEvery(actions.SAVE_INTO_FIRESTORE, storeIntoFirestore),
    takeEvery(actions.DELETE_MEDIA_FROM_STORAGE, deleteMedia),
    takeEvery(actions.FIRESTORE_UPDATE_SPOT_REQUEST, updateSpotInFirestore),
    takeEvery(actions.FIRESTORE_UPDATE_TRAIL_REQUEST, updateTrailInFirestore),
    takeEvery(actions.DELETE_SPOT_FROM_FIRESTORE, deleteSpotFirestore),
    takeEvery(actions.DELETE_TRAIL_FROM_FIRESTORE, deleteTrailFirestore),
  ])
}
