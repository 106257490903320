import { call, all, takeEvery, put, fork } from 'redux-saga/effects'
import { createBrowserHistory } from 'history'
import firebase from 'firebase'
import notification from '../../components/Notification'
import { getToken, clearToken } from '@iso/lib/helpers/utility'
import actions from './actions'

const history = createBrowserHistory()

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function* ({ payload }) {
    const { token, user } = payload
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token: token,
      })
    } else {
      notification('error', 'Something went wrong! Please, try again later.')
      yield put({ type: actions.LOGIN_ERROR })
    }
  })
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
    yield localStorage.setItem('id_token', payload.token)
  })
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function* () {})
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    yield call(clearToken)
    yield call(() => firebase.auth().signOut())
    history.push('/')
  })
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    const token = getToken().get('idToken')
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
      })
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
  ])
}
