const DOCUMENT = 'COMMUNITY_'

const actions = {
  LOAD_FROM_FIRESTORE: DOCUMENT + 'LOAD_FROM_FIRESTORE',
  LOAD_FROM_FIRESTORE_SUCCESS: DOCUMENT + 'LOAD_FROM_FIRESTORE_SUCCESS',
  LOAD_FROM_FIRESTORE_ERROR: DOCUMENT + 'LOAD_FROM_FIRESTORE_ERROR',

  LOAD_MEMBERS: DOCUMENT + 'LOAD_MEMBERS',
  LOAD_MEMBERS_SUCCESS: DOCUMENT + 'LOAD_MEMBERS_SUCCESS',
  LOAD_MEMBERS_ERROR: DOCUMENT + 'LOAD_MEMBERS_ERROR',

  EDIT_MEMBER_TYPE: DOCUMENT + 'EDIT_MEMBER_TYPE',
  EDIT_MEMBER_TYPE_SUCCESS: DOCUMENT + 'EDIT_MEMBER_TYPE_SUCCESS',
  EDIT_MEMBER_TYPE_ERROR: DOCUMENT + 'EDIT_MEMBER_TYPE_ERROR',

  SAVE_INTO_FIRESTORE: DOCUMENT + 'SAVE_INTO_FIRESTORE',
  SAVE_INTO_FIRESTORE_SUCCESS: DOCUMENT + 'SAVE_INTO_FIRESTORE_SUCCESS',
  SAVE_INTO_FIRESTORE_ERROR: DOCUMENT + 'SAVE_INTO_FIRESTORE_ERROR',

  FIRESTORE_UPDATE_COMMUNITY_REQUEST:
    DOCUMENT + 'FIRESTORE_UPDATE_COMMUNITY_REQUEST',
  FIRESTORE_UPDATE_COMMUNITY_SUCCESS:
    DOCUMENT + 'FIRESTORE_UPDATE_COMMUNITY_SUCCESS',
  FIRESTORE_UPDATE_COMMUNITY_ERROR:
    DOCUMENT + 'FIRESTORE_UPDATE_COMMUNITY_ERROR',

  TOGGLE_COMMUNITY_MODAL: DOCUMENT + 'TOGGLE_COMMUNITY_MODAL',
  TOGGLE_MEMBERS_MODAL: DOCUMENT + 'TOGGLE_MEMBERS_MODAL',

  HANDLE_CHANGE: DOCUMENT + 'HANDLE_CHANGE',

  DELETE_MEDIA_FROM_STORAGE: DOCUMENT + 'DELETE_MEDIA_FROM_STORAGE',

  CLEAR_STATE: DOCUMENT + 'CLEAR_STATE',

  clearCommunitiesState: () => ({
    type: actions.CLEAR_STATE,
  }),

  loadCommunities: () => {
    return { type: actions.LOAD_FROM_FIRESTORE }
  },

  loadCommunitiesSuccess: communities => ({
    type: actions.LOAD_FROM_FIRESTORE_SUCCESS,
    payload: { communities },
  }),

  loadCommunitiesError: error => ({
    type: actions.LOAD_FROM_FIRESTORE_ERROR,
    payload: { error },
  }),

  loadMembers: communityId => ({
    type: actions.LOAD_MEMBERS,
    payload: { communityId },
  }),

  loadMembersSuccess: members => ({
    type: actions.LOAD_MEMBERS_SUCCESS,
    payload: { members },
  }),

  loadMembersError: error => ({
    type: actions.LOAD_MEMBERS_ERROR,
    payload: { error },
  }),

  editMember: data => ({
    type: actions.EDIT_MEMBER_TYPE,
    payload: { ...data },
  }),

  toggleCommunityModal: (data = null) => ({
    type: actions.TOGGLE_COMMUNITY_MODAL,
    payload: { data },
  }),

  toggleMembersModal: modalState => ({
    type: actions.TOGGLE_MEMBERS_MODAL,
    payload: { modalState },
  }),

  handleChange: (form, name, value) => ({
    type: actions.HANDLE_CHANGE,
    payload: { form, name, value },
  }),

  storeIntoFirestore: data => ({
    type: actions.SAVE_INTO_FIRESTORE,
    payload: { data },
  }),

  storeIntoFirestoreSuccess: () => ({
    type: actions.SAVE_INTO_FIRESTORE_SUCCESS,
  }),

  saveIntoFireStoreError: error => ({
    type: actions.SAVE_INTO_FIRESTORE_ERROR,
    payload: { error },
  }),

  updateCommunityInFirestore: editedCommunity => ({
    type: actions.FIRESTORE_UPDATE_COMMUNITY_REQUEST,
    payload: { editedCommunity },
  }),

  deleteMedia: (type, file) => ({
    type: actions.DELETE_MEDIA_FROM_STORAGE,
    payload: { type, file },
  }),
}
export default actions
