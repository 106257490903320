export const AUTH_PROVIDER = {
  EMAIL_PASS: 'email_pass',
  FACEBOOK: 'facebook',
  GOOGLE: 'google',
  APPLE: 'apple',
}

export const COLLECTIONS = {
  PHOTO: 'NotePhotos',
  AUDIO: 'NoteAudio',
  NOTES: 'notes',
  SPOTS: 'spots',
  USERS: 'users',
  COMMUNITIES: 'communities',
  MEMBERS: 'members',
}
