import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import actions from './actions'
import firebase from 'firebase'
import { omit, isEqual } from 'lodash'
import { rsf, db, storage } from '@iso/lib/firebase/firebase'
import notification from '../../components/Notification'
import { getFileNameFromURL } from '../../library/helpers/utility'
import {
  convertCollectionsSnapshotToMap,
  deleteDocuments,
  addCollectionAndDocuments,
} from '@iso/lib/firebase/firebase.util'
import moment from 'moment'
import { COLLECTIONS } from '../../constants'
import { createBrowserHistory } from 'history'

const history = createBrowserHistory()

const communitiesRef = db.collection(COLLECTIONS.COMMUNITIES)
const usersRef = db.collection(COLLECTIONS.USERS)
const membersRef = db.collection(COLLECTIONS.MEMBERS)

function* loadCommunities() {
  const {
    profile: { profile },
  } = yield select()
  try {
    const user = profile

    const membersSnapshot = yield call(() => {
      return membersRef.get()
    })

    const membersCommunityIds = membersSnapshot.docs.map(
      member => member.data().communityId?.id
    )

    const userCommunitiesIds = yield call(() => {
      return membersSnapshot.docs
        .map(member => {
          if (member?.data().userId.id === usersRef.doc(user.id).id) {
            return {
              communityId: member.data().communityId?.id,
              type: member?.data().type,
            }
          }
        })
        .filter(member => member)
    })

    const communitiesSnapshot = yield call(() => {
      return communitiesRef.get()
    })

    const allCommunities = yield call(() =>
      communitiesSnapshot.docs
        .map(communityDoc => {
          let matchCommunities = userCommunitiesIds.find(
            community => community.communityId === communityDoc.id
          )

          if (matchCommunities) {
            return {
              ...communityDoc.data(),
              id: communityDoc.id,
              ownerId: communityDoc.data().ownerId.id,
              memberType: matchCommunities.type,
              totalMembers: membersCommunityIds.filter(
                id => id === communityDoc.id
              ).length,
            }
          }
        }, [])
        .filter(community => community)
    )

    const sortDates = (a, b) => {
      return a.createdAt.toMillis() - b.createdAt.toMillis()
    }

    const communities = allCommunities
      .map(community => ({
        ...community,
      }))
      .sort(sortDates)
      .reverse()
    yield put(actions.loadCommunitiesSuccess(communities))
  } catch (error) {
    console.log(error)
    notification('error', 'Something went wrong!')
    yield put(actions.loadCommunitiesError(error))
  }
}

function* loadMembers({ payload }) {
  const {
    profile: { profile, users },
  } = yield select()
  try {
    const membersSnapshot = yield call(() => {
      return membersRef
        .where('communityId', '==', communitiesRef.doc(payload.communityId))
        .get()
    })

    const members = yield call(() =>
      membersSnapshot.docs.map(membersDoc => {
        return {
          id: membersDoc.id,
          ...membersDoc.data(),
          userId: membersDoc.data().userId.id,
          communityId: membersDoc.data().communityId.id,
          userDetails:
            users && users.find(user => user.id === membersDoc.data().userId.id),
        }
      }, [])
    )

    yield put(actions.loadMembersSuccess(members))
  } catch (error) {
    console.log(error)
    notification('error', 'Something went wrong!')
    yield put(actions.loadMembersError(error))
  }
}

function* editMember({ payload }) {
  const { memberId } = payload
  const { type } = payload

  try {
    yield call(() => {
      membersRef.doc(memberId).update({
        type: type,
      })
    })

    yield put({
      type: actions.EDIT_MEMBER_TYPE_SUCCESS,
    })
  } catch (err) {
    yield put({
      type: actions.EDIT_MEMBER_TYPE_ERROR,
    })
    console.log(err)
    notification('error', 'Something went wrong!')
  }
}

function* storeIntoFirestore({ payload: { data } }) {
  const {
    profile: { profile },
  } = yield select()

  try {
    const user = profile

    const createdAt = Date.now()

    const newCommunity = yield call(
      rsf.firestore.addDocument,
      COLLECTIONS.COMMUNITIES,
      {
        ownerId: usersRef.doc(user.id),
        createdAt: firebase.firestore.Timestamp.fromMillis(createdAt),
        name: data?.name ? data?.name : 'no name',
        description: data?.description,
        type: data?.type ? data?.type : 'public',
        secretCode: data?.secretCode ? data?.secretCode : null,
        category: data?.category ? data?.category : [],
        questionForMembers: data?.questionForMembers
          ? data?.questionForMembers
          : null,
        rules: data?.rules,
        isCurated: data?.isCurated ? data?.isCurated : false,
        brandIdentity: {
          ...data.brandIdentity,
        },
      }
    )

    yield call(rsf.firestore.addDocument, COLLECTIONS.MEMBERS, {
      communityId: communitiesRef.doc(newCommunity.id),
      userId: usersRef.doc(user.id),
      type: 'owner',
    })

    notification('success', 'Community created!')

    yield all([
      put({ type: actions.SAVE_INTO_FIRESTORE_SUCCESS }),
      put({ type: actions.LOAD_FROM_FIRESTORE }),
    ])
  } catch (error) {
    console.log(error)
    notification('error', 'Something went wrong!')
    yield put(actions.saveIntoFireStoreError(error))
  }
}

function* updateCommunityInFirestore({ payload: { editedCommunity } }) {
  const {
    profile: { profile },
  } = yield select()

  const user = profile
  const communityId = editedCommunity.id

  try {
    yield call(() => {
      return communitiesRef.doc(communityId).update({
        ...editedCommunity,
        ownerId: usersRef.doc(user.id),
      })
    })

    yield all([
      put({ type: actions.FIRESTORE_UPDATE_COMMUNITY_SUCCESS }),
      put({
        type: actions.LOAD_FROM_FIRESTORE,
      }),
    ])
  } catch (error) {
    console.log(error)
    notification('error', 'Something went wrong!')
    yield put({ type: actions.FIRESTORE_UPDATE_COMMUNITY_ERROR })
  }
}

function* deleteMedia({ payload: { type, file } }) {
  const storageRef = storage.ref()
  const filePath = getFileNameFromURL(file)
  const mediaRef = storageRef.child(filePath)

  try {
    mediaRef.delete().then(() => notification('success', 'File deleted!'))
  } catch (err) {
    console.log(err)
    notification('error', 'File not found!')
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_FIRESTORE, loadCommunities),
    takeEvery(actions.LOAD_MEMBERS, loadMembers),
    takeEvery(actions.EDIT_MEMBER_TYPE, editMember),
    takeEvery(actions.SAVE_INTO_FIRESTORE, storeIntoFirestore),
    takeEvery(
      actions.FIRESTORE_UPDATE_COMMUNITY_REQUEST,
      updateCommunityInFirestore
    ),
    takeEvery(actions.DELETE_MEDIA_FROM_STORAGE, deleteMedia),
  ])
}
