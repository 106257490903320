// saga.js
import { all, takeEvery, put, select, fork, call } from 'redux-saga/effects'
import profileActions from './actions'
import firebase from 'firebase'
import omit from 'lodash/omit'
import notification from '../../components/Notification'

const USER_PROFILE_FIELDS = [
  'displayName',
  'photoURL',
  'email',
  'phoneNumber',
  'contacts',
  'username',
  'invitedBy',
  'inviteKey',
  'hasFinishedOnboarding',
  'coverPhotoURL'
]

const USER_UPDATABLE_FIELDS = ['displayName', 'photoURL']

const usersRef = firebase.firestore().collection('users')

const USER_PROFILE_FIELDS_UPDATERS = {
  contacts: contacts => firebase.firestore.FieldValue.arrayUnion(...contacts)
}

export function* updateUserProfile({ payload }) {
  const {
    profile: { profile }
  } = yield select()

  const user = yield call(async () => {
    await firebase.auth().currentUser?.updateProfile(
      USER_UPDATABLE_FIELDS.reduce((data, key) => {
        return {
          ...data,
          ...(payload?.hasOwnProperty(key) && {
            [key]: payload[key]
          })
        }
      }, {})
    )
    return firebase.auth().currentUser?.toJSON()
  })

  const updates = payload ? { ...user, ...payload } : user

  const usernamesRef = firebase.firestore().collection('usernames')

  if (updates?.username && profile?.username !== updates?.username) {
    yield call(() =>
      usernamesRef
        ?.doc(updates.username)
        .set({ userId: usersRef?.doc(user?.uid) })
    )
    if (profile?.username) {
      yield call(() => usernamesRef.doc(profile.username).delete())
    }
  }

  try {
    if (updates && updates.uid) {
      yield call(() =>
        usersRef?.doc(updates?.uid).set(
          USER_PROFILE_FIELDS.reduce(
            (data, key) => ({
              ...data,

              ...(updates.hasOwnProperty(key) && {
                [key]: USER_PROFILE_FIELDS_UPDATERS.hasOwnProperty(key)
                  ? USER_PROFILE_FIELDS_UPDATERS[key](updates[key])
                  : updates[key]
              })
            }),
            {}
          ),
          { merge: true }
        )
      )
    }
    if (user && user.uid) {
      const result = yield call(() => usersRef?.doc(user.uid).get())

      yield put({
        type: profileActions.UPDATE_USER_PROFILE_SUCCESS,
        profile: { ...result.data(), id: result.id }
      })
    }
  } catch (e) {
    yield put({ type: profileActions.UPDATE_USER_PROFILE_ERROR })
    console.log(e)
  }
}

export function* getUsersProfile() {
  const {
    profile: { profile }
  } = yield select()
  try {
    const user = profile

    const allUsersDocs = yield call(() => {
      return usersRef.get()
    })

    const allUsers = yield call(() => {
      return allUsersDocs.docs.map(user => {
        return {
          ...omit(user.data(), [
            'contacts',
            'email',
            'phoneNumber',
            'hasFinishedOnboarding',
            'tokens'
          ]),
          id: user.id
        }
      })
    })

    yield put({
      type: profileActions.GET_PREMIUM_USERS_PROFILES_SUCCESS,
      users: allUsers
    })
  } catch (error) {
    console.log(error)
    notification('error', 'Something went wrong!')
    yield put(profileActions.GET_PREMIUM_USERS_PROFILES_ERROR)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(profileActions.UPDATE_USER_PROFILE_REQUEST, updateUserProfile),
    takeEvery(
      profileActions.GET_PREMIUM_USERS_PROFILES_REQUEST,
      getUsersProfile
    )
  ])
}
