export default {
  siteName: 'ChalkNotes',
  siteIcon: 'ion-flash',
  footerText: `ChalkNotes @ ${new Date().getFullYear()}`,
  enableAnimatedRoute: false,
  apiUrl: 'http://yoursite.com/api/',
  google: {
    analyticsKey: 'UA-xxxxxxxxx-1',
  },
  dashboard: '/dashboard',
  bundleName: 'com.chalknotes.app',
  iosAppStoreId: '1510634728',
};
