const DOCUMENT = 'NOTE_'

const actions = {
  LOAD_FROM_FIRESTORE: DOCUMENT + 'LOAD_FROM_FIRESTORE',
  LOAD_FROM_FIRESTORE_SUCCESS: DOCUMENT + 'LOAD_FROM_FIRESTORE_SUCCESS',
  LOAD_FROM_FIRESTORE_ERROR: DOCUMENT + 'LOAD_FROM_FIRESTORE_ERROR',

  SAVE_INTO_FIRESTORE: DOCUMENT + 'SAVE_INTO_FIRESTORE',
  SAVE_INTO_FIRESTORE_ERROR: DOCUMENT + 'SAVE_INTO_FIRESTORE_ERROR',

  TOGGLE_FIRESTORE_HANDLE_MODAL: DOCUMENT + 'TOGGLE_FIRESTORE_HANDLE_MODAL',
  TOGGLE_TRAIL_MODAL: DOCUMENT + 'TOGGLE_TRAIL_MODAL',
  TOGGLE_REORDER_MODAL: DOCUMENT + 'TOGGLE_REORDER_MODAL',

  HANDLE_CHANGE: DOCUMENT + 'HANDLE_CHANGE',
  HANDLE_PRICE: DOCUMENT + 'HANDLE_PRICE',
  HANDLE_CURRENCY: DOCUMENT + 'HANDLE_CURRENCY',

  ADD_DRAFTSPOT: DOCUMENT + 'ADD_DRAFTSPOT',
  ADD_TRAIL: DOCUMENT + 'ADD_TRAIL',
  ADD_SPLIT_QUESTION: DOCUMENT + 'ADD_SPLIT_QUESTION',

  EDIT_SPOT_LOCATION: DOCUMENT + 'EDIT_SPOT_LOCATION',
  EDIT_SPOT_PREVSPOTS: DOCUMENT + 'EDIT_SPOT_PREVSPOTS',

  DELETE_DRAFTSPOT: DOCUMENT + 'DELETE_DRAFT_SPOT',

  DELETE_MEDIA_FROM_STORAGE: DOCUMENT + 'DELETE_MEDIA_FROM_STORAGE',

  FIRESTORE_UPDATE_SPOT_REQUEST: DOCUMENT + 'FIRESTORE_UPDATE_SPOT_REQUEST',
  FIRESTORE_UPDATE_SPOT_SUCCESS: DOCUMENT + 'FIRESTORE_UPDATE_SPOT_SUCCESS',
  FIRESTORE_UPDATE_SPOT_ERROR: DOCUMENT + 'FIRESTORE_UPDATE_SPOT_ERROR',

  FIRESTORE_UPDATE_TRAIL_REQUEST: DOCUMENT + 'FIRESTORE_UPDATE_TRAIL_REQUEST',
  FIRESTORE_UPDATE_TRAIL_SUCCESS: DOCUMENT + 'FIRESTORE_UPDATE_TRAIL_SUCCESS',
  FIRESTORE_UPDATE_TRAIL_ERROR: DOCUMENT + 'FIRESTORE_UPDATE_TRAIL_ERROR',

  DELETE_SPOT_FROM_FIRESTORE: DOCUMENT + 'DELETE_SPOT_FROM_FIRESTORE',

  DELETE_TRAIL_FROM_FIRESTORE: DOCUMENT + 'DELETE_TRAIL_FROM_FIRESTORE',

  ADD_SPOT_TO_EXISTING_TRAIL: DOCUMENT + 'ADD_SPOT_TO_EXISTING_TRAIL',

  CLEAR_STATE: DOCUMENT + 'CLEAR_STATE',
  CLEAR_SPOTS_STATE: DOCUMENT + 'CLEAR_SPOTS_STATE',
  EDIT_SPOTS: DOCUMENT + 'EDIT_SPOTS',

  loadFromFireStore: () => {
    return { type: actions.LOAD_FROM_FIRESTORE }
  },

  loadFromFireStoreSuccess: (sentNotes, usersNotes, allPublicNotes) => ({
    type: actions.LOAD_FROM_FIRESTORE_SUCCESS,
    payload: { sentNotes, usersNotes, allPublicNotes },
  }),

  loadFromFireStoreError: error => ({
    type: actions.LOAD_FROM_FIRESTORE_ERROR,
    payload: { error },
  }),
  toggleModal: (data = null) => ({
    type: actions.TOGGLE_FIRESTORE_HANDLE_MODAL,
    payload: { data },
  }),

  toggleTrailModal: (data = null) => ({
    type: actions.TOGGLE_TRAIL_MODAL,
    payload: { data },
  }),

  toggleReorderModal: (data = null) => ({
    type: actions.TOGGLE_REORDER_MODAL,
    payload: { data }
  }),

  editSpotLocation: (id, location) => ({
    type: actions.EDIT_SPOT_LOCATION,
    payload: { id, location },
  }),

  deleteDraftSpot: spot => ({
    type: actions.DELETE_DRAFTSPOT,
    payload: { spot },
  }),

  handleChange: (form, name, value) => ({
    type: actions.HANDLE_CHANGE,
    payload: { form, name, value },
  }),

  handlePrice: value => ({
    type: actions.HANDLE_PRICE,
    payload: { value }
  }),

  handleCurrency: value => ({
    type: actions.HANDLE_CURRENCY,
    payload: { value }
  }),

  addDraftSpot: draftSpot => ({
    type: actions.ADD_DRAFTSPOT,
    payload: { draftSpot },
  }),

  addTrailInfo: trailData => ({
    type: actions.ADD_TRAIL,
    payload: { trailData },
  }),

  addSplitQuestion: (splitQuestion, id) => ({
    type: actions.ADD_SPLIT_QUESTION,
    payload: { splitQuestion, id }
  }),

  saveIntoFireStore: (data, actionName = 'insert') => ({
    type: actions.SAVE_INTO_FIRESTORE,
    payload: { data, actionName },
  }),

  saveIntoFireStoreError: error => ({
    type: actions.SAVE_INTO_FIRESTORE_ERROR,
    payload: { error },
  }),

  updateSpotInFirestore: editedSpot => ({
    type: actions.FIRESTORE_UPDATE_SPOT_REQUEST,
    payload: { editedSpot },
  }),

  updateTrailInFirestore: editedTrail => ({
    type: actions.FIRESTORE_UPDATE_TRAIL_REQUEST,
    payload: { editedTrail },
  }),

  addNewSpotToTrail: (trail, spots) => ({
    type: actions.ADD_SPOT_TO_EXISTING_TRAIL,
    payload: { trail, spots },
  }),

  deleteMedia: (type, file) => ({
    type: actions.DELETE_MEDIA_FROM_STORAGE,
    payload: { type, file },
  }),

  clearNotesState: () => ({
    type: actions.CLEAR_STATE,
  }),

  clearSpotsListState: () => ({
    type: actions.CLEAR_SPOTS_STATE,
  }),

  editSpots: spots => ({
    type: actions.EDIT_SPOTS,
    payload: { data: spots }
  }),

  deleteSpotFirestore: spot => ({
    type: actions.DELETE_SPOT_FROM_FIRESTORE,
    payload: { spot },
  }),

  deleteTrailFirestore: trail => ({
    type: actions.DELETE_TRAIL_FROM_FIRESTORE,
    payload: { trail },
  }),
}
export default actions
