export const colors = {
  blue: {
    dark: '#1C2E4B',
    darkSlate: '#415277',
    deep: 'rgb(0, 121, 191)',
    light: 'lightblue',
    lighter: '#d9fcff',
    soft: '#E6FCFF'
  },
  pinkHorizon: '#EE4DB8',
  skyBlue: '#21D0FE',
  purpleChalk: '#D667CD', //colored text
  emergencyPink: '#FF268E', //expiring alerts text
  darkGrey: '#1D222D', //paragraph text color & overlays
  lightGrey: '#EEE', //navigation bar & input fields
  white: '#FEFEFE', // light backgrounds
  blackChalk: '#0A122B', //if black is definately needed
  blackBoard: '#00192B', //dark backgrounds
  sherbert: '#FFD2A6', //for fun ;)
  pinkLight: '#FFE7F7',
  greenOlive: '#4E9912',
  mediumOrchid: '#AF4FA8',

  red: '#FF0000',

  black: '#000',
  shadow: 'rgba(0,0,0,0.2)',
  grey: {
    darker: '#333',
    dark: '#33435d',
    medium: '#8993a1',
    N30: '#EBECF0',
    light: '#F4F5F7'
  },
  green: 'rgb(185, 244, 188)',
  white: '#fff',
  purple: 'rebeccapurple',
  orange: '#FF991F'
}

export const gradient = {
  main: 'linear-gradient(135deg, #21D0FD 0%, #EE4DB9 100%)',
  grayScale:
    'linear-gradient(291.68deg,#c4c4c4 -2.2%,rgba(196, 196, 196, 0) 119.48%)'
}

export const grid = 8

export const borderRadius = 2
const rowStyle = {
  width: '100%',
  display: 'flex',
  flexFlow: 'row wrap'
}
const colStyle = {
  marginBottom: '16px'
}
const gutter = 16

const splitColStyle = {
  marginBottom: '16px',
  backgroundColor: colors.darkGrey,
  borderRadius: '24px'
}
const splitRowStyle = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexFlow: 'row wrap'
}

const basicStyle = {
  rowStyle,
  colStyle,
  gutter,
  splitColStyle,
  splitRowStyle
}
export default basicStyle
