import actions from './actions'
import { BRAND_IDENTITY_FIELDS } from '@iso/containers/Communities/CommunitiesConstants'

const initState = {
  isLoading: false,
  isLoadingMembers: false,
  isLoadingMemberType: false,
  errorMessage: false,
  communities: null,
  members: null,
  modalActive: false,
  membersModalActive: false,
  draftCommunity: null,
  firestoreEdit: false,
}

export default function reducer(
  state = initState,
  { type, payload, newRecord }
) {
  switch (type) {
    case actions.CLEAR_STATE:
      return {
        ...state,
        isLoading: false,
        draftCommunity: initState.draftCommunity,
        firestoreEdit: false,
      }
    case actions.TOGGLE_COMMUNITY_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        draftCommunity: payload.data
          ? payload.data.community
          : initState.draftCommunity,
        firestoreEdit: payload.data
          ? payload.data.firestoreEdit
          : initState.firestoreEdit,
      }

    case actions.TOGGLE_MEMBERS_MODAL:
      return {
        ...state,
        membersModalActive: !state.membersModalActive,
        members: state.membersModalActive ? null : state.members,
      }

    case actions.EDIT_MEMBER_TYPE:
      return {
        ...state,
        isLoadingMemberType: true,
      }

    case actions.EDIT_MEMBER_TYPE_SUCCESS:
      return {
        ...state,
        isLoadingMemberType: false,
      }

    case actions.EDIT_MEMBER_TYPE_ERROR:
      return {
        ...state,
        isLoadingMemberType: false,
      }

    case actions.SAVE_INTO_FIRESTORE_SUCCESS:
      return {
        ...state,
        modalActive: false,
        isLoading: false,
      }

    case actions.FIRESTORE_UPDATE_COMMUNITY_REQUEST:
      return {
        ...state,
        errorMessage: false,
        modalActive: false,
      }
    case actions.FIRESTORE_UPDATE_COMMUNITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case actions.FIRESTORE_UPDATE_COMMUNITY_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'There is a loading problem',
      }

    case actions.HANDLE_CHANGE:
      let newData = Object.assign({}, state.draftCommunity)
      if (BRAND_IDENTITY_FIELDS.includes(payload.name)) {
        newData.brandIdentity = {
          ...state.draftCommunity?.brandIdentity,
          [payload.name]: payload.value,
        }
      } else {
        newData[payload.name] = payload.value
      }
      return {
        ...state,
        draftCommunity: newData,
      }

    case actions.LOAD_FROM_FIRESTORE:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      }
    case actions.LOAD_FROM_FIRESTORE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        communities: payload.communities,
        errorMessage: false,
      }
    case actions.LOAD_FROM_FIRESTORE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'There is a loading problem',
      }

    case actions.LOAD_MEMBERS:
      return {
        ...state,
        isLoadingMembers: true,
        errorMessage: false,
        modalActive: false,
      }
    case actions.LOAD_MEMBERS_SUCCESS:
      return {
        ...state,
        isLoadingMembers: false,
        members: payload.members,
        errorMessage: false,
      }
    case actions.LOAD_MEMBERS_ERROR:
      return {
        ...state,
        isLoadingMembers: false,
        errorMessage: 'There is a loading problem',
      }

    default:
      return state
  }
}
