import styled from 'styled-components'
import { palette, font } from 'styled-theme'
import { colors } from '@iso/assets/styles/constants'

const AudioPlayerWrap = styled.div`
  .controls {
    display: flex;
    align-items: center;
  }

  .playback-button {
    transition: 0.2s;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 32px;
    cursor: pointer;
  }

  .playback-button svg {
    transition: 0.2s;
    width: 18px;
    height: 18px;
    color: ${colors.white};
  }

  .playback-button {
    &:hover {
      svg {
        width: 18px;
        height: 18px;
        color: #73e7fb;
        transform: scale(1.03);
      }
    }
  }

  .timebar {
    min-width: 120px;
    flex: 1 1;
    margin-top: 12px;
  }

  .timebar-bar {
    height: 4px;
  }

  .timebar-circle {
    background-color: ${colors.white};
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: absolute;
    top: 0px;
    transform: translate(-50%, calc(-50% + 2px));
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }

  .timebar-time-info {
    margin-top: 8px;
    font-size: 11px;
    color: ${colors.grey.light};
    display: flex;
    justify-content: space-between;
  }
`

export default AudioPlayerWrap
