// export default {
//   apiKey: 'AIzaSyAXaDthtRZhBpo0QwHnPEJWb15Rs9aw1S8',
//   authDomain: 'chalknotesstaging.firebaseapp.com',
//   databaseURL: 'https://chalknotesstaging.firebaseio.com',
//   projectId: 'chalknotesstaging',
//   storageBucket: 'chalknotesstaging.appspot.com',
//   messagingSenderId: '380928943172',
//   appId: '1:380928943172:web:1db6d797616766291c4811',
//   measurementId: 'G-N2ZSGZPP16',
// };

// config for production
export default {
  apiKey: 'AIzaSyDAqWdhGxvDIN53PSmZ0GW0ajN_7ghRRzs',
  authDomain: 'chalknotes-platform.firebaseapp.com',
  projectId: 'chalknotes-platform',
  storageBucket: 'chalknotes-platform.appspot.com',
  messagingSenderId: '972426943798',
  appId: '1:972426943798:web:ad02cf849fa1ba5dfd2797',
  measurementId: 'G-X4Z8G99WFL'
}
