const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  SET_USER: 'SET_USER',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  setUser: user => ({
    type: actions.SET_USER,
    payload: user,
  }),
  login: (token, user) => ({
    type: actions.LOGIN_REQUEST,
    payload: { token, user },
  }),
  logout: () => ({
    type: actions.LOGOUT,
  }),
}
export default actions
