import { useState, useCallback, useEffect } from 'react'
import { uuid } from 'uuidv4'
import { storage } from '@iso/lib/firebase/firebase'
import notification from '../../components/Notification'

export default function useUploadFile(collection) {
  const [uploadData, setUploadData] = useState(null)

  const uploadFile = useCallback(
    async file => {
      if (file) {
        try {
          const storageRef = storage.ref()
          const result = await storageRef
            .child(`${collection}/${uuid()}`)
            .put(file)

          const fileUrl = await result.ref.getDownloadURL()
          setUploadData({ ...result, fileUrl })
          return { ...result, fileUrl }
        } catch (err) {
          notification('error', 'Something went wrong, try to upload again.')
          return null
        }
      }
    },
    [collection]
  )

  return [uploadFile, uploadData]
}
