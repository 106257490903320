const profileActions = {
  UPDATE_USER_PROFILE_REQUEST: 'UPDATE_USER_PROFILE_REQUEST',
  UPDATE_USER_PROFILE_ERROR: 'UPDATE_USER_PROFILE_ERROR',
  UPDATE_USER_PROFILE_SUCCESS: 'UPDATE_USER_PROFILE_SUCCESS',

  GET_PREMIUM_USERS_PROFILES_REQUEST: 'GET_PREMIUM_USERS_PROFILES_REQUEST',
  GET_PREMIUM_USERS_PROFILES_SUCCESS: 'GET_PREMIUM_USERS_PROFILES_SUCCESS',
  GET_PREMIUM_USERS_PROFILES_ERROR: 'GET_PREMIUM_USERS_PROFILES_ERROR',

  updateUserProfile: user => ({
    type: profileActions.UPDATE_USER_PROFILE_REQUEST,
    payload: user,
  }),

  getUsersProfile: () => ({
    type: profileActions.GET_PREMIUM_USERS_PROFILES_REQUEST,
  }),
}

export default profileActions
