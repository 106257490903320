import { combineReducers } from 'redux';
import App from '@iso/redux/app/reducer';
import Auth from '@iso/redux/auth/reducer';
import Calendar from '@iso/redux/calendar/reducer';
import Notes from '@iso/redux/notes/reducers';
import Communities from '@iso/redux/communities/reducers';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import modal from '@iso/redux/modal/reducer';
import profile from '@iso/redux/profile/reducer';

import authActions from '@iso/redux/auth/actions';

const appReducer = combineReducers({
  Auth,
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  Calendar,
  Notes,
  Communities,
  modal,
  profile,
});

const rootReducer = (state, action) => {
  let newState = state;
  // when a logout action is dispatched it will reset redux state
  if (action.type === authActions.LOGOUT) {
    newState = undefined;
  }

  return appReducer(newState, action);
};

export default rootReducer;
