import React from 'react'
import ImageCellView from './ImageCell'
import DeleteCell from './DeleteCell'
import EditableCell from './EditableCell'
import FilterDropdown from './FilterDropdown'
import AudioRecorderWrap from '../../../src/containers/Forms/AudioRecorder/AudioRecorderWrap'

const DateCell = data => <p>{data.toLocaleString()}</p>
const ImageCell = src => <ImageCellView src={src} />
const LinkCell = (link, href) => <a href={href ? href : '#'}>{link}</a>
const TextCell = text => <p>{text}</p>
const AudioCell = src => (
  <AudioRecorderWrap form="spot" name="audio" value={src} />
)

export {
  DateCell,
  ImageCell,
  AudioCell,
  LinkCell,
  TextCell,
  EditableCell,
  DeleteCell,
  FilterDropdown,
}
